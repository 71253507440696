/* Custom CSS Variables */
:root {
  --white-bg: #ffffff;
  --black-bg: #040a16;
  --white-color: #ffffff;
  --black-color: #040a16;
  --green-bg: #00ac57;
  --green-color: #00ac57;
  --light-blue: #3e45ff;
  --grey: #7c7c7c;
  --gray: #9c9c9c;
  --header-box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  --box-shadow-1: 0px 4px 31px -1px rgba(0, 0, 0, 0.14);
  --box-shadow-2: 0px 4px 24px -1px rgba(0, 0, 0, 0.13);
  --box-shadow-3: 0px 4px 14px -1px rgba(0, 0, 0, 0.09);
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
*::selection {
  background-color: #3e45ff;
  color: #fff;
}
::placeholder {
  font-size: 1rem;
}
a {
  text-decoration: none;
  display: block;
  padding: 0.7rem;
  text-transform: capitalize;
  color: var(--black-color);
  font-size: 0.878rem;
  font-weight: 400;
  cursor: pointer;
}
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  background: var(--white-bg);
  color: var(--black-color);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input,
button {
  box-shadow: none;
  border: none;
  outline: none;
}
.back-arrow-section {
  text-align: right;
  margin-bottom: 0;
}
.back-arrow-container {
  border: 1px solid var(--green-color);
  height: 48px;
  width: 48px;
  border-radius: 50%;
  line-height: 48px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.blank-cta-btn,
.cta-btn-primary,
.cta-btn-secondary,
button {
  padding: 0.8rem 1.2rem;
  display: inline-block;
  background: #1e2ca8;
  box-shadow: none;
  border: none;
  color: #fff;
  /* border-radius: 30px; */
  border-radius: 8px;
  text-transform: capitalize;
  cursor: pointer;
  text-align: center;
}
.blank-cta-btn {
  background: #ffffff;
  border: 1px solid #00ac57;
  color: #00ac57;
}
.cta-btn-primary {
  background: #00ac57;
}

.cta-btn-container {
  text-align: center;
}
.cta-btn a {
  color: #fff;
}
.card-container > * {
  margin-bottom: 2rem;
}

.card {
  box-shadow: var(--box-shadow-1);
}
.grid-2 > *,
.flex-container > * {
  margin-bottom: 6rem;
}
.flex-btn {
  display: none;
  margin-top: 1.4rem;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
p {
  color: #1e1e1e;
  font-size: 0.878rem;
}
img {
  width: 100%;
  display: block;
  border-radius: inherit;
}

/* define default font */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  text-transform: capitalize;
  color: #1e1e1e;
  font-weight: 800;
}
h1 {
  font-size: 1.9rem;
}
h2 {
  font-size: 1.5rem;
  font-weight: 700;
}
h3 {
  font-size: 1.3rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 0.878rem;
}
header,
section {
  padding: 0.5rem 5%;
  margin-bottom: 8.5rem;
}

/*  */

/* ================================
   General form input styles
  ====================================*/
form .form-field {
  position: relative;
  border-radius: 5px;
  margin-bottom: 1.4rem;
}
form .form-field label {
  text-transform: capitalize;
  margin-bottom: 0.45rem;
  font-weight: 400;
  display: block;
}
form .form-field input,
form .form-field textarea {
  width: 100%;
  padding: 0.7rem;
  border-radius: 5px;
  border: none;
  box-shadow: none;
  outline: none;
}
form .form-field .eye-icon-container {
  position: absolute;
  top: 70%;
  transform: translate(0%, -50%);
  right: 15px;
  cursor: pointer;
  display: inline-block;
  width: 25px;
  height: 25px;
}
.hr-line {
  background: #e2dcff;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10px;
  margin: 0 0 0 0;
  width: 100%;
}

/* FOR FLEXED ITEMS */
.flex-container-2 > *,
.flex-container-4 > *,
.grid-container-3 > * {
  margin-bottom: 4.5rem;
}

/* ================================
  Media Queries for diff screen
  ====================================*/
@media (min-width: 980px) {
  h1 {
    font-size: 3.12rem;
  }
  h2 {
    font-size: 2.412rem;
  }
  h3 {
    font-size: 2.212rem;
  }
  h4 {
    font-size: 1.812rem;
  }
  h5 {
    font-size: 1rem;
  }
  section {
    padding: 1rem 8%;
  }
  p {
    font-size: 1rem;
  }
  .form-field {
    /* width: 50%; */
  }
  .flex-container-2 {
    display: flex;
    flex-flow: row wrap;
    gap: 4%;
    align-items: center;
  }
  .flex-container-4 {
    display: flex;
    flex-flow: row wrap;
    gap: 4%;
  }
  .flex-container-4 > *,
  .flex-container-2 > * {
    flex-basis: 48%;
  }
  .grid-container-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;
  }
  /* .grid-container-3 > *:nth-child(4),
  .grid-container-3 > *:nth-child(5) {
    place-self: center;
  } */
  .flex-container-2 > *,
  .grid-container-3 > * {
    margin-bottom: 0;
  }
}

/* LARGE DESKTOP */
@media (min-width: 1200px) {
  .flex-container-4 > * {
    flex-basis: 22%;
    margin-bottom: 0;
  }
}
