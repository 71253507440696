/* ABout us styles goes here */

/* About us Styles */
.services-section.aboutus h2 {
  text-align: center;
}
.services-section.aboutus h3 {
  color: var(--green-color);
}
.team-section .read-more,
.services-section.aboutus .read-more {
  max-height: 240px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.team-section .active,
.services-section.aboutus .active {
  overflow: auto;
  max-height: 500px;
  transition: max-height 0.2s linear;
  padding: 1rem;
}
.team-section .active::-webkit-scrollbar,
.services-section.aboutus .active::-webkit-scrollbar {
  height: 5px;
  width: 4px;
}
.team-section .active::-webkit-scrollbar-thumb,
.services-section.aboutus .active::-webkit-scrollbar-thumb {
  background: var(--green-bg);
  border-radius: 8px;
}

/* -----------------WHAT WE SPECIALIZE -------------- */
.our-specialization {
  background: var(--green-bg);
  margin-bottom: 0;
  padding: 45px 5%;
}
.our-specialization h3 {
  color: #fff;
  text-align: center;
}
.our-specialization .card {
  background: #fff;
  border-radius: 20px;
  padding: 1rem;
}
.our-specialization .card .img-container {
  height: 35px;
  width: 35px;
  /* margin:auto; */
  margin-bottom: 1rem;
}

/* =============TEAM========= */
.team-section h3 {
  text-align: center;
  color: #00ac57;
}
.team-section .flex-container-2 {
  /* justify-content: space-between;
  gap: 10%;
  margin-bottom: 4.8rem; */
  align-items: flex-start;
}
.team-section .flex-container-2 > * {
  /* flex-basis: 42%; */
}
.team-section .team-member .img-container {
  border-radius: 20px;
  display: inline-block;
  overflow: hidden;
  background: #a8643c;
  margin-bottom: 2.5rem;
}
.team-section .team-member h4 {
  color: #1e1e1e;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0;
}
.team-section .team-member .icon-container {
  width: 24px;
  height: 24px;
  padding: 0;
}
.team-section .team-member .inner-flex {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 4%;
}
.team-section .team-member .inner-section p {
  font-weight: 600;
  font-size: 0.857rem;
  margin-bottom: 1.8rem;
  color: #666c66;
}
.team-section .team-member .bottom-section p {
  font-size: 0.857rem;
  font-weight: 400;
  color: #666c66;
}
.team-section .team-member .cta-btn-container {
  text-align: left;
}

/* 
===========================
DESKTOP DEVICE
===========================
 */
@media (min-width: 980px) {
  .our-specialization {
    padding: 45px 8%;
  }
  .our-specialization .card {
    min-height: 450px;
  }
  .team-section .team-member .inner-section p {
    font-size: 1rem;
  }
  .team-section .team-member .bottom-section p {
    font-size: 1rem;
  }
  .team-section .team-member .img-container {
    height: 384px;
    width: 384px;
  }
}
