.footer-container {
  background: #1e2ca8;
  margin-bottom: 0;
  color: #fff;
  /* text-align: center; */
}
.footer-container p,
.footer-container h5 {
  color: #fff;
}
.footer-container h5 {
  font-size: 1.25rem;
  font-weight: 700;
}
.footer-container .flex-btn .cta-btn {
  /* border-radius: 10px;
  background: #10ba21;
  padding: 12px 20px;
  cursor: pointer;
  margin-top: 1rem;
  color: #fff;
  text-transform: capitalize; */
}
.footer-container .flex-container-2 {
  /* flex-flow:nowrap row; */
  justify-content: space-between;
  align-content: flex-end;
}
.footer-container .flex-container-2 > *:first-child {
  margin-right: auto;
  flex-basis: 52%;
}
.footer-container .flex-container-2 > *:last-child {
  /* flex-basis:auto; */
}
.footer-container .flex-container-2 > *:last-child ul {
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  gap: 4%;
}
.footer-container .cta-btn-container {
  position: absolute;
  top: 0;
  right: 0;
  text-align: initial;
  height: 100%;
}
.footer-container form .form-field {
  width: 100%;
}
.footer-container form .form-field input {
  background-color: #fff;
  width: 85%;
}
.footer-container .cta-btn-container button {
  margin-top: 0;
}

.footer-container .flex-container-2 > * {
  flex-basis: auto;
  flex-basis: 48%;
}

.footer-container .flex-container-2 p {
  font-weight: 500;
}
.footer-container .flex-1 {
  align-items: flex-start;
  justify-content: space-between;
}

.footer-container .flex-1 a {
  color: #fff;
  font-weight: 600;
}
.footer-container .flex-1 ul ul a {
  font-weight: 400;
  font-size: 1rem;
}
.footer-container .flex-1 > *:first-child .logo-container {
  width: 68px;
  height: 33px;
  padding: 0;
}

.footer-container .flex-1 > *:first-child > * {
  margin-bottom: 2rem;
}

.footer-container .flex-1 > *:last-child ul ul {
  display: block;
}
.footer-container .flex-1 > *:last-child .list-items > li {
  margin-bottom: 1.4rem;
}
.footer-container .flex-container-2.flex-2 {
  margin-top: 2rem;
  align-items: center;
}

/* ===============================
 Social Media links
=================================*/
.footer-container .social-media-links .inner-flex-2 {
  display: flex;
  gap: 0.8rem;
}
.footer-container .social-media-links .img-container {
  height: 36px;
  width: 36px;
  display: inline-block;
  padding: 0;
}
.footer-container .social-media-links img {
  height: fit-content;
}
/* .footer-container .social-media-links .img-container{
height:48px;
width:48px;
} */

/* 
=======================================
Large Desktop Screen
=======================================
*/
@media (min-width: 980px) {
  .footer-container .flex-container-2 > *:last-child {
    flex-basis: auto;
  }
  .footer-container .flex-container-2 > *:last-child ul {
    justify-content: flex-end;
  }
  .footer-container .flex-container-2 > *:first-child {
    flex-basis: 48%;
  }
}

/* ========================
for large desktop screen
==========================*/
@media (min-width: 1200px) {
  .footer-container .flex-1 > *:last-child {
    /* flex-basis: 48%; */
  }
}
