/* ==========================
 Header Styles
==============================*/
.header {
  /* border: 1px solid green; */
  /* padding: 1rem; */
  margin-bottom: 1rem;
  /* box-shadow: var(--header-box-shadow); */
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  gap: 4%;
  /* border: 1px solid red; */
}
.header-container > *:first-child {
  flex-basis: 120px;
}
.header .list-menu {
  padding: 1rem;
}
.header .list-menu a {
  color: #fff;
  font-weight: 600;
}

.toggle-btn {
  font-size: 1.8rem;
  cursor: pointer;
  color: #1e2ca8;
  text-align: right;
}
.close-toggle-btn {
  color: #fff;
}

/* =====================
  toggle menu styles
  ========================*/
.nav-menu {
  /* border: 1px solid red; */
}
.open-nav-container {
  /* border: 2px solid yellow; */
  display: none;
}
.close-nav-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding: 2rem 9%;
  background: #1e2ca8;
  color: #fff;
  /* border: 2px solid yellow; */
  z-index: 1020;
}

/* ==========user account=========== */
.nav-menu .dropdown {
  position: relative;
}
.nav-menu .user-account {
  display: flex;
  align-items: flex-start;
}
.nav-menu .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid teal;
  text-align: center;
  z-index: 1010;
  border-radius: 4px;
  border: 1px solid #cbd8cc;
  background: #fff;
  /* padding: 1rem; */
  width: 50%;
}
.nav-menu .dropdown-menu a {
  font-weight: 600;
  color: #000;
}
.nav-menu .dropdown-menu li + li {
  border-top: 1px solid #cbd8cc;
}
/* ===================Cart Section============== */

.add-to-cart {
  position: relative;
  z-index: 1000;
}
.add-to-cart .cart-counter {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  width: 15px;
  display: inline-block;
  background-color: red;
  border-radius: 50%;
  z-index: 998;
}

/* ================================
  Media Queries for diff devices
  ====================================*/
@media (min-width: 980px) {
  .header-container {
    /* align-items: center; */
  }
  .header-container > * {
    /* border: 1px solid red; */
  }
  .header-container > *:first-child {
    margin-right: auto;
    flex-basis: 120px;
  }
  /* .header-container > *:last-child {
    border: 1px solid green;
  } */
  .header .list-menu {
    display: flex;
    /* align-items: center; */
    flex-flow: row wrap;
  }
  .header .list-menu .active {
    /* color: #000080; */
  }
  .header .list-menu a:not(.list-menu .active):hover {
    /* border-bottom: 2px solid var(--green-color); */
    /* color: #000080; */
  }
  .open-nav-container {
    display: block;
  }
  .open-btn-icon {
    display: none;
  }
  .header .list-menu a {
    color: #000;
  }
  .nav-menu .dropdown-menu {
    width: 100%;
  }
  .close-nav-container {
    position: static;
    top: auto;
    right: auto;
    height: auto;
    width: auto;
    background: transparent;
    color: initial;
    display: none;
  }
}
